











































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import {
  CustomerDto,
  CustomerSetEnterpriseAccountQuotaDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "SetAccountAmount",
})
export default class SetAccountAmount extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  loading = true;
  show = false;
  form: CustomerSetEnterpriseAccountQuotaDto = {};
  defaultData: CustomerSetEnterpriseAccountQuotaDto = {
    id: 0,
    enterpriseAccountQuota: undefined,
    openAccountDays: undefined,
  };

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      if (this.dataId) {
        api.customer
          .setEnterpriseAccountQuota({
            body: this.form,
          })
          .then(() => {
            this.show = false;
            this.$message.success(
              (this as any).$l.getLanguageText("basic.updateSuccess").toString()
            );
            // this.$message.success("更新成功");
          });
      }
    });
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        await api.customer
          .get({ id: this.dataId })
          .then((res: CustomerDto) => {
            this.form = {
              id: res.id,
              enterpriseAccountQuota: res.accountQuota,
              openAccountDays: res.openAccountDays,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.defaultData };
        this.loading = false;
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    enterpriseAccountQuota: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "customer.enterpriseAccountQuota"
        ),
        trigger: "blur",
      },
    ],
    openAccountDays: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "customer.enterOpenAccountDays"
        ),
        trigger: "blur",
      },
      {
        type: "number",
        min: 1,
        message: (this as any).$l.getLanguageText(
          "customer.openAccountDaysAtLeast0"
        ),
        trigger: "blur",
      },
    ],
  };

  get title() {
    return (this as any).$l
      .getLanguageText("customer.setAccountQuota")
      .toString();
  }

  cancel() {
    this.show = false;
  }
}
