


















































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { CustomerDto, CustomerType } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import AbSelect from "@/components/AbSelect.vue";
import SetAccountAmount from "@/views/customer/enterprise/setAccountAmount.vue";

@Component({
  components: {
    SetAccountAmount,
    PagedTableView,
    AbSelect,
  },
})
export default class Enterprise extends Vue {
  editId = 0;
  queryForm = {
    customerCode: "",
    enterpriseNameOrCall: "",
    isActive: undefined,
    customerType: CustomerType.Enterprise,
  };

  fetchData(params: never) {
    return api.customer.getAll(params);
  }

  // 新建
  handleCreate() {
    this.editId = 0;
    // (this.$refs.editForm as any).show = true;
    this.$router.push({
      name: "enterpriseCreate",
    });
  }

  // 编辑
  handleEdit(index: number, row: CustomerDto) {
    this.editId = row!.id!;
    // (this.$refs.editForm as any).show = true;
    // (this.$refs.editForm as any).form = row
    this.$router.push({
      name: "enterpriseEdit",
      params: { id: row.id! + "" },
    });
  }
  // 跳转详情页
  handleDetail(index: number, row: CustomerDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "enterpriseDetail",
      params: { id: row.id!.toString() },
    });
  }

  //禁用
  handleDisable(index: number, row: CustomerDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.disableTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(() => {
      api.user
        .deActivate({
          body: {
            id: row.fkUser?.id,
          },
        })
        .then(() => {
          this.$message.success(
            (this as any).$l.getLanguageText("basic.disableSuccess").toString()
          );
        });
    });
  }

  //启用
  handleEnable(index: number, row: CustomerDto) {
    api.user
      .activate({
        body: {
          id: row.fkUser?.id,
        },
      })
      .then(() => {
        this.$message.success(
          (this as any).$l.getLanguageText("basic.enableSuccess").toString()
        );
      });
  }

  handleSetAccountAmount(index: number, row: CustomerDto) {
    this.editId = row.id!;
    (this.$refs.dataForm as any).show = true;
    (this.$refs.dataForm as any).form = row;
  }
}
